enum ApiTags {
  agenti = 'AGENTI',
  cart = 'CART',
  clienti = 'CLIENTI',
  dettaglioOrdini = 'DETTAGLIO_ORDINI',
  login = 'LOGIN',
  manualOfflineMode = 'MANUAL_OFFLINE_MODE',
  me = 'ME',
  prodotti = 'PRODOTTI',
  prodotto = 'PRODOTTO',
  scadenziario = 'SCADENZIARIO',
  statoOrdini = 'STATO_ORDINI',
  varianti = 'VARIANTI',
  venduto = 'VENDUTO'
}

const isOffline = () => !!localStorage.getItem('manual-offline');

enum LSKeys {
  manualOffline = 'manual-offline',
  me = 'me',
}

enum Paths {
  agenti = '/agenti',
  catalogo = '/catalogo',
  clienti = '/clienti',
  dettaglioOrdini = '/dettaglio-ordini',
  home = '/',
  login = '/login',
  nuovoOrdine = '/nuovo-ordine',
  scadenziario = '/scadenziario',
  shop = '/shop',
  shopCategories = '/shop/categories',
  shopProduct = '/shop/categories/:category1/:category2/:sku',
  shopProducts = '/shop/categories/:category1/:category2',
  statoOrdini = '/stato-ordini',
  venduto = '/venduto',
}

enum FixedCacheKeys {
}

enum FeatureFlags {}

export {
  ApiTags,
  Paths,
  FeatureFlags,
  FixedCacheKeys,
  isOffline,
  LSKeys,
};
