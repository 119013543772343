import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showDatiCliente: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    showDati: (state) => {
      state.showDatiCliente = true;
    },
    hideDati: (state) => {
      state.showDatiCliente = false;
    },
  },
});

export const { actions } = dashboardSlice;
export default dashboardSlice.reducer;
