import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function getCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const neededCookies: {[key:string]: string | undefined} = {
  PHPSESSID: undefined,
  'vsf-fgppwa-token': 'logged-operatore',
};

const hasValidCookies = () => {
  const keys = Object.keys(neededCookies);

  // Check if any cookie is invalid
  const hasInvalidCookies = keys.some((key) => {
    const cookie = document.cookie.match(key);
    const cookieValue = getCookie(key);
    return !cookie || (!!neededCookies[key] && neededCookies[key] !== cookieValue);
  });

  return !hasInvalidCookies;
};

const useCookies = (to: string, { inverted = false } = {}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const validCookies = hasValidCookies();
    if ((inverted && validCookies) || (!inverted && !validCookies)) {
      navigate(to, { replace: true });
    }
  }, [inverted, navigate, to]);
};

export default useCookies;
export { hasValidCookies, neededCookies };
