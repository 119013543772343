import axios, { Method } from 'axios';
import type { AxiosError } from 'axios';
import { hasValidCookies } from '@Src/hooks/useCookies';

type checkCookiesBaseQueryType = {
  baseURL?: string,
  data?: NonNullable<unknown>,
  headers?: NonNullable<unknown>,
  method: Method,
  skipCookieCheck?: boolean,
  url: string,
  withCredentials?: boolean,
}

export const checkCookiesBaseQuery = async ({
  baseURL = process.env.REACT_APP_BASE_URL,
  url,
  method,
  data,
  skipCookieCheck = false,
  withCredentials = true,
}: checkCookiesBaseQueryType) => {
  try {
    if (!skipCookieCheck && !hasValidCookies()) {
      throw new Error('Cookies are invalid');
    }

    try {
      const result = await axios({
        baseURL,
        url,
        method,
        data,
        withCredentials,
      });

      if (result.headers['x-total-count'] !== undefined) {
        return {
          data: {
            items: result.data,
            xTotalCount: +result.headers['x-total-count'],
          },
        };
      }

      return { data: result.data };
    } catch (e) {
      // @ts-expect-error Non so perchè si lamenta
      const axiosError: AxiosError = e;
      return {
        error: { status: axiosError.response?.status, data: axiosError.response?.data },
      };
    }
  } catch (invalidCookiesError) {
    return {
      error: { status: undefined, data: invalidCookiesError },
    };
  }
};
