import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateType = {
  destinazioneOrdine?: string;
};

const initialState: StateType = {
  destinazioneOrdine: undefined,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setDestinazioneOrdine: (state, { payload }: PayloadAction<string>) => {
      state.destinazioneOrdine = payload;
    },
  },
});

export const { actions } = cartSlice;
export default cartSlice.reducer;
