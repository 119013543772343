import {
  BrowserRouter, Route, Routes, useNavigate,
} from 'react-router-dom';
import React, {
  lazy, memo, useEffect, useState,
} from 'react';
import { Paths } from '@Src/constants';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import { useOrientation } from 'react-use';
import { isMobile } from 'react-device-detect';
import { initDB } from '@Helpers/db';
import authApiSlice from '@State/auth';
import { ErrorBoundary } from 'react-error-boundary';

const { useLogoutMutation } = authApiSlice;

const Login = lazy(() => import('@Containers/Login/index'));
const Main = lazy(() => import('@Containers/Main/index'));

function Router() {
  const isDBReady = useInitDB();
  const { type: orientation } = useOrientation();

  if (!isDBReady) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        Caricamento...
      </div>
    );
  }

  if (isMobile && orientation.includes('portrait')) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        Posiziona il dispositivo in orizzontale
      </div>
    );
  }

  return (
    <BrowserRouter>

      <Routes>
        <Route element={<Login />} path={Paths.login} />

        <Route element={<AuthenticatedRoute />}>
          <Route element={<SafeMain />} path="*" />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

function MyErrorBoundary() {
  const [triggerLogout] = useLogoutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    triggerLogout(null).then(() => {
      navigate(Paths.login, { replace: true });
    });
  }, [triggerLogout, navigate]);

  return (
    <p>Something went wrong</p>
  );
}

function SafeMain() {
  return (
    <ErrorBoundary FallbackComponent={MyErrorBoundary}>
      <Main />
    </ErrorBoundary>
  );
}

const useInitDB = () => {
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  useEffect(() => {
    initDB().then(() => {
      setIsDBReady(true);
    });
  }, []);

  return isDBReady;
};

export default memo(Router);
